export function normalizeAlgoliaProductData(item) {
  return {
    ...item,
    images: [
      {
        originalSrc: item.product_image
      }
    ],
    variants: null,
    colors: null
  }
}
