import classNames from 'classnames'
import { THEME } from 'utils'

export default function SidebarWidget({ children, className }) {
  return (
    <>
      <div className={classNames('sidebarWidget', className)}>{children}</div>
      <style jsx>{`
        .sidebarWidget {
          margin-bottom: 20px;
          border: 1px solid ${THEME.COLORS.LIGHTGRAY};
          padding: 20px 20px 0 20px;
          background: #fff;
        }
        .rankings-sidebar {
          padding: 0;
        }
      `}</style>
    </>
  )
}
