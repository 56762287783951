import React from 'react'
import { observer } from 'mobx-react-lite'

import { THEME } from 'utils'
import { useStore } from 'hooks'
import { BrandsStore } from 'stores/brands'

import SidebarAbout from './SidebarAbout'
import BrandDetailHeader from './BrandDetailHeader'

// sidebar brand-specifc
import TheDozenRankingsSidebar from 'modules/TheDozen/components/rankings-sidebar'
import TheDozenNextMatchSidebar from 'modules/TheDozen/components/next-match'
import TheDozenMerchSidebar from 'modules/TheDozen/components/merch-sidebar'

const THE_DOZEN_ID = 114

const BrandDetailContainer = ({ children }) => {
  const brandsStore = useStore(BrandsStore)

  if (!brandsStore.current) return null

  const brand = brandsStore.current
  const foregroundColor = brand.colors?.secondary || '#ffffff'

  function getSidebarComponent(brandId) {
    switch (brandId) {
      case THE_DOZEN_ID:
        return (
          <>
            <TheDozenNextMatchSidebar />
            <TheDozenRankingsSidebar />
            <TheDozenMerchSidebar />
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <BrandDetailHeader />
      <div className='container'>
        <div className='row row--main'>
          <div className='col col--main'>{children}</div>
          <div className='col col--sidebar'>
            <aside className='sidebar'>
              {getSidebarComponent(Number(brand?.id))}
              <SidebarAbout />
            </aside>
            {/* <aside className='sidebar'>
              <SidebarVideo />
            </aside> */}
            {/* <aside className='sidebar'>
              <SidebarProducts />
            </aside> */}
          </div>
        </div>
      </div>
      <style jsx>{`
        .sidebar {
          width: 100%;
          margin-left: auto;
          background: ${THEME.COLORS.BACKGROUND_GRAY};
        }
        .col {
          padding: 0;
        }
        .col--main {
          order: 2;
        }
        .brandHeader {
          width: 100%;
          margin-top: -20px;
          background-color: ${brand.colors.primary};
        }
        .headerContent {
          max-width: 1200px;
          height: 200px;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          position: relative;
        }
        .headerText {
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;
          bottom: 0;
          width: 100%;
          z-index: 2;
          padding: 16px;
        }
        .headerText .title {
          color: ${foregroundColor};
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 34px;
          text-transform: uppercase;
        }
        .headerText .tagline {
          font-size: 12px;
          font-weight: bold;
          color: ${brand.colors.secondary || '#ffffff'};
        }
        .headerImage {
          width: 100%;
          height: 100%;
          opacity: 0.3;
          background-size: cover;
          background-position: center;
          position: relative;
          background-image: url(${brand.cover_photo});
        }
        .socialLinks {
          padding-top: 28px;
          display: flex;
          align-items: center;
        }
        .socialLinks > span,
        .socialLinks > a {
          padding-right: 16px;
        }
        .subnavbar {
          background: #ffffff;
          border-bottom: 1px solid #ddd;
          margin-bottom: 16px;
        }
        .subnavbar a {
          font-size: 1.25rem !important;
        }
        .subnavbarContent {
          max-width: 1200px;
          height: 100%;
          margin: 0 auto;
        }
        .subnavbarContent a {
          text-transform: uppercase;
          color: #777777;
          font-size: 16px;
          font-weight: bold;
          padding: 12px 0;
          margin: 0 12px;
          display: inline-block;
        }
        .subnavbarContent a.active {
          color: #333333;
          border-bottom: 4px solid ${THEME.COLORS.PRIMARY};
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .brandHeader {
            margin-top: 0px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .headerContent {
            height: 380px;
          }
          .headerText {
            align-items: flex-start;
          }
          .headerText .title {
            font-size: 52px;
          }
          .headerText .tagline {
            font-size: 16px;
          }
          .subnavbarContent a {
            font-size: 18px;
            padding: 16px 0;
            margin: 0 16px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .sidebar {
            display: block;
            max-width: 300px;
            max-width: 100%;
            margin: 0;
          }
          .col {
            padding: 0 15px;
          }
          .col--main {
            order: 0;
            flex-basis: 66.6667%;
            max-width: 66.6667%;
          }
          .col--sidebar {
            flex-basis: 33.3333%;
            max-width: 33.3333%;
            min-width: 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .headerImage {
            width: 65%;
            opacity: 1;
          }
          .headerContent {
            position: initial;
          }
          .headerText {
            padding: 96px 16px 16px;
            width: 35%;
            position: initial;
            flex-direction: column;
            justify-content: initial;
          }
        }
        @media (min-width: 75rem) {
          .col--main {
            flex-basis: 70.8883%;
            max-width: 70.8883%;
          }
          .col--sidebar {
            flex-basis: 29.1117%;
            max-width: 29.1117%;
            min-width: 0;
          }
        }
      `}</style>
    </>
  )
}

export default observer(BrandDetailContainer)
