import Link from 'next/link'
import { THEME } from 'utils'
import Arrow from 'icons/Arrow'

export default function AboutLink({ href, as, children }) {
  return (
    <>
      <Link href={href} as={as} legacyBehavior>
        <div className='aboutLink'>
          <a>{children}</a>
          <span>
            <Arrow direction='right' size='12px' color='#777777' style={{ transform: 'rotate(90deg)' }} />
          </span>
        </div>
      </Link>
      <style jsx>{`
        .aboutLink {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #777777;
          cursor: pointer;
          padding: 20px;
          margin: 20px -20px 0;
          border-top: 1px solid ${THEME.COLORS.LIGHTGRAY};
        }
      `}</style>
    </>
  )
}
