import { useState, useEffect } from 'react'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'

import flatten from 'lodash/flatten'

export function useTheDozenMerch(_, params, config) {
  const cacheKey = JSON.stringify(params ?? {})

  const fetcher = async () => {
    return []
  }
  return useSWR([cacheKey], fetcher, config)
}

const LIMIT = 20

export const useInfiniteMerch = (params, config) => {
  const [hasMore, setHasMore] = useState(true)
  const cacheKey = JSON.stringify(params ?? {})

  const fetcher = async () => {
    return []
  }
  const { data: _data, ...props } = useSWRInfinite((pageIndex) => [pageIndex, cacheKey], fetcher, config)

  async function handleNext() {
    if (!hasMore) return
    return props.setSize(props.size + 1)
  }

  useEffect(() => {
    if (_data.length && _data[_data.length - 1].length < LIMIT) {
      setHasMore(false)
    }
  }, [_data.length])

  const data = flatten(_data)
  return { data, handleNext, hasMore, ...props }
}
