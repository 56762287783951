import TeamColumn from 'modules/TheDozen/components/team-column'
import { formatDateToTimeZone, THEME } from 'utils'

export default function UpcomingMatch({ children, match_number, matches, match_date, isLive = false }) {
  const date = match_date && formatDateToTimeZone(match_date, 'EEE, MMM d, yyyy', 'UTC')
  return (
    <div>
      <div className='match-details'>
        <div className='match-header'>
          <div>{date}</div>
          {isLive && <div className='badge'>Live</div>}
        </div>
        {matches?.map((match) => {
          return (
            <div className='details-inner' key={match.airtable_field_id}>
              <div className='score-column'>
                <h4>Match {match_number}</h4>
                <div className='team-logo top'>
                  <TeamColumn {...match?.team_a} />
                </div>
                <div className='team-logo'>
                  <TeamColumn {...match?.team_b} />
                </div>
              </div>
              {children}
            </div>
          )
        })}
      </div>
      <style jsx>{`
        .match-details {
          padding: 20px 0 0 0;
        }
        .match-header {
          display: flex;
          justify-content: space-between;
        }
        .badge {
          color: #fff;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: bold;
          line-height: 16px;
          background: #eb3e3e;
          padding: 4px 8px;
          border-radius: 24px;
          margin-right: 5px;
        }
        .team_logo.top {
          margin-bottom: 5px;
        }
        .details-inner {
          width: 100%;
          border-top: 1px solid #d8d8d8;
          padding: 10px 0 0 0;
          margin: 5px 0 0 0;
          display: grid;
          align-items: center;
          grid-template-columns: 1fr;
        }
        .score-column {
          display: flex;
          height: 100%;
          flex-direction: column;
        }
        h4 {
          padding-top: 2px;
          margin-top: 0;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: normal;
          letter-spacing: 0.8px;
          color: #777777;
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .details-inner {
            padding: 5px 0 0 0;
            margin: 5px 0 32px 0;
          }
          h4 {
            margin-bottom: 8px;
          }
        }
      `}</style>
    </div>
  )
}
