import { useMemo } from 'react'
import { theDozenScheduleApi } from 'api'
import { useFind } from 'hooks/data/common'
import uniq from 'lodash/uniq'
import reverse from 'lodash/reverse'
import compact from 'lodash/compact'

export function useTheDozenSchedule(params, config) {
  return useFind(theDozenScheduleApi, params, config)
}

export function useGroupedSchedule(schedule) {
  const resultsByMatchday = useMemo(() => {
    return groupMatchesByDay({
      data: schedule?.filter((item) => item.match_number),
      date: 'match_date',
      dates: uniq(compact(schedule.map((item) => item.match_date)))
    })
  }, [schedule])

  const upcoming = useMemo(
    () => Object.values(resultsByMatchday).filter((item) => item.upcoming_game),
    [resultsByMatchday]
  )
  const pastMatches = useMemo(
    () => reverse(Object.values(resultsByMatchday).filter((item) => !item.upcoming_game)),
    [resultsByMatchday]
  )

  return { upcomingMatches: upcoming, pastMatches }
}

function groupMatchesByDay({ data, dates, date }) {
  return dates.reduce((acc, cur) => {
    const matchday = {
      ...data.find((item) => item[date] === cur),
      matches: data.filter((item) => {
        return item[date] === cur
      })
    }
    acc[cur] = matchday
    return acc
  }, [])
}
