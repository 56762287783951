import React from 'react'
import { useRouter } from 'next/router'
import { useTheDozenRankings } from 'modules/TheDozen/hooks/rankings'
import { THEME } from 'utils'

import TeamColumn from 'modules/TheDozen/components/team-column'
import StaticTable from 'components/StaticTable'
import SidebarWidget from 'components/SidebarWidget'
import MediaQuery from 'components/MediaQuery'
import AboutLink from 'components/AboutLink'

export default function RankingsSidebar() {
  const router = useRouter()
  const { id, slug } = router.query
  const { data: rankings } = useTheDozenRankings()

  const week = rankings?.[0]?.week
  const top10 = rankings?.slice(0, 10)

  const columns = [
    {
      id: 'ranking',
      header: '#',
      cell: React.memo(({ rank }) => <strong>{rank}</strong>),
      styles: { justifyContent: 'start' }
    },
    {
      id: 'team',
      header: 'Team',
      cell: React.memo((stats) => <TeamColumn {...stats?.team} />),
      styles: { justifyContent: 'start' }
    }
  ]
  return (
    <>
      <SidebarWidget>
        <div>
          <h3>Week {week} Rankings</h3>
          <MediaQuery minWidth={THEME.BREAKPOINTS.SM}>
            <div className='table-wrapper'>
              <StaticTable data={top10} columns={columns} />
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={THEME.BREAKPOINTS.SM - 1}>
            <div className='table-wrapper'>
              <StaticTable data={top10?.slice(0, 5)} columns={columns} />
            </div>
          </MediaQuery>
        </div>
        <AboutLink href='/shows/[id]/[slug]/rankings' as={`/shows/${id}/${slug}/rankings`}>
          See Full Rankings
        </AboutLink>
      </SidebarWidget>
      <style jsx>{`
        h3 {
          padding-top: 0;
          margin-top: 0;
          margin-bottom: 18px;
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 29px;
          text-transform: uppercase;
        }
        .table-wrapper {
          padding: 10px 0 0 0;
          overflow: hidden;
        }
      `}</style>
    </>
  )
}
