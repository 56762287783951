import React from 'react'
import { useRouter } from 'next/router'
import { useTheDozenMerch } from 'modules/TheDozen/hooks/merch'
import { THEME } from 'utils'
import StoreProduct from 'components/StoreProduct'

import SidebarWidget from 'components/SidebarWidget'
import AboutLink from 'components/AboutLink'
import { normalizeAlgoliaProductData } from 'modules/TheDozen/utils'

export default function MerchSidebar() {
  const router = useRouter()
  const { id, slug } = router.query

  const { data: products } = useTheDozenMerch('', { hitsPerPage: 3 })

  return (
    <>
      <SidebarWidget>
        <div>
          <h3>Shop The Dozen</h3>
          <div className='products-list'>
            {products?.map((product) => {
              return (
                <div className='product-col' key={product.id}>
                  <StoreProduct product={normalizeAlgoliaProductData(product)} renderShadow={false} />
                </div>
              )
            })}
          </div>
        </div>
        <AboutLink href='/shows/[id]/[slug]/merch' as={`/shows/${id}/${slug}/merch`}>
          Shop All
        </AboutLink>
      </SidebarWidget>
      <style jsx>{`
        h3 {
          padding-top: 0;
          margin-top: 0;
          margin-bottom: 18px;
          padding-bottom: 10px;
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 29px;
          text-transform: uppercase;
        }
        .products-list {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
        .product-col {
          border-bottom: 1px solid #d8d8d8;
          padding-bottom: 30px;
        }
        .product-col:last-of-type {
          border-bottom: none;
        }
      `}</style>
    </>
  )
}
