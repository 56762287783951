import React from 'react'
import classNames from 'classnames'
import { THEME } from 'utils'

export function StaticTable({ columns, data, rowIdentifier = 'id', showTableHead = true, stickyTable = false }) {
  return (
    <>
      <table className={classNames({ sticky: stickyTable })}>
        {showTableHead && (
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.id} width={column.width} minwidth={column.minWidth} maxwidth={column.maxWidth}>
                  {column?.tooltip ? (
                    <div className='col-header-tooltip' style={{ ...column.styles }}>
                      <div className='col-header'>{column.header}</div>
                      <div
                        className='tooltip header-tooltip tooltip-rounded tooltip-content-small'
                        data-tooltip={column.tooltip}
                      >
                        ?
                      </div>
                    </div>
                  ) : (
                    <div className='col-header' style={{ ...column.styles }}>
                      {column.header}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {data?.map((row, rowIndex) => {
            const isRowEven = (rowIndex + 1) % 2 === 0

            return (
              <tr key={row[rowIdentifier]}>
                {columns.map((column) => {
                  return (
                    <>
                      {stickyTable && column.sticky ? (
                        <th
                          key={`${row[rowIdentifier]}-${column.id}`}
                          width={column.width}
                          style={{ ...column.styles, background: isRowEven ? '#fff' : '#f8f8f8f8' }}
                        >
                          {column.disabled ? <span className='disabled'> - </span> : <column.cell {...row} />}
                        </th>
                      ) : (
                        <td
                          key={`${row[rowIdentifier]}-${column.id}`}
                          width={column.width}
                          style={{ ...column.styles }}
                        >
                          {column.disabled ? <span className='disabled'> - </span> : <column.cell {...row} />}
                        </td>
                      )}
                    </>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <style jsx>{`
        table {
          border-spacing: 0;
          width: 100%;
          background: #fff;
        }
        th,
        td {
          font-weight: normal;
          letter-spacing: 1.5px;
        }
        td {
          font-size: 10px;
          text-align: center;
        }
        thead {
          background: white;
        }
        thead tr {
          background: #ffffff;
          text-align: left;
        }
        thead th:first-of-type {
          padding-left: 16px;
        }
        thead th:last-of-type {
          padding-right: 16px;
        }
        .col-header {
          text-transform: uppercase;
          font-size: 12px !important;
          letter-spacing: 0.8px;
          color: #999999;
          display: flex;
          justify-content: center;
          text-align: center;
        }
        th {
          padding: 4px 8px;
        }
        td {
          padding: 2px 8px;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0;
          text-align: center;
        }
        td:first-of-type {
          padding-left: 16px;
        }
        td:last-of-type {
          padding-right: 16px;
        }
        th:first-of-type {
          border-right: none;
        }
        tbody tr:nth-child(odd) {
          background: #f8f8f8f8;
        }
        tr:nth-child(even) {
          background: transparent;
        }
        .col-header-tooltip {
          display: flex;
          gap: 3px;
          justify-content: center;
          align-items: center;
        }
        .header-tooltip {
          color: #999999;
          border: 1px solid #999999;
          border-radius: 100%;
          height: 12px;
          width: 12px;
          font-size: 8px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding-left: 2px;
          cursor: pointer;
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .header-tooltip {
            display: none;
          }
          table.sticky thead th {
            position: sticky;
            background: #ffffff;
            z-index: 1;
            width: 25vw;
            top: 0;
          }
          table.sticky {
            margin: 0;
            border: none;
            border-collapse: separate;
            border-spacing: 0;
          }
          table.sticky tbody th {
            position: relative;
          }
          table.sticky tbody td {
            z-index: 1;
          }
          table.sticky tbody th {
            position: sticky;
            left: 0;
            z-index: 1;
          }
          table.sticky thead th:nth-child(2) {
            position: sticky;
            left: 0;
            z-index: 3 !important;
          }
          table.sticky tbody th,
          table.sticky thead th:nth-child(2) {
            border-right: 1px solid #d8d8d8;
          }
          table.sticky thead th {
            border-top: 1px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;
          }
        }
        @media (min-width: 360px) {
          td {
            font-size: 12px;
          }
          th {
            font-size: 12px;
          }
        }
        @media (min-width: 420px) {
          td {
            font-size: 14px;
          }
          th {
            font-size: 14px;
          }
        }
      `}</style>
    </>
  )
}

export default StaticTable
