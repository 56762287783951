import React from 'react'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { useStore } from 'hooks'
import { BrandsStore } from 'stores/brands'
import { THEME } from 'utils'
import SidebarWidget from 'components/SidebarWidget'
import AboutLink from 'components/AboutLink'

const SidebarAbout = () => {
  const router = useRouter()
  const { id, slug } = router.query
  const brandsStore = useStore(BrandsStore)
  const brand = brandsStore.current
  return (
    <div className='sidebarAbout'>
      <SidebarWidget>
        <h3>Hosts</h3>
        {brand.authors.map((author) => (
          <div className='author' key={author.name}>
            <img className='avatar' src={author.avatar} alt={author.name} />
            <div className='name'>{author.name}</div>
          </div>
        ))}
        <AboutLink href='/shows/[id]/[slug]/about' as={`/shows/${id}/${slug}/about`}>
          About The Show
        </AboutLink>
      </SidebarWidget>
      <style jsx>{`
        .sidebarAbout {
          display: none;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .sidebarAbout {
            display: block;
          }
        }
        h3 {
          margin-top: 0;
          margin-bottom: 24px;
          padding-bottom: 14px;
          border-bottom: 1px solid ${THEME.COLORS.LIGHTGRAY};
        }
        .author {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
        }
        .avatar {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          margin-right: 16px;
        }
        .name {
          font-weight: bold;
          font-size: 16px;
        }
        .aboutLink {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #777777;
          cursor: pointer;
          padding: 20px;
          margin: 20px -20px 0;
          border-top: 1px solid ${THEME.COLORS.LIGHTGRAY};
        }
      `}</style>
    </div>
  )
}

export default observer(SidebarAbout)
