import React from 'react'
import { useRouter } from 'next/router'
import { useTheDozenSchedule } from 'modules/TheDozen/hooks/schedule'
import { THEME } from 'utils'

import SidebarWidget from 'components/SidebarWidget'
import AboutLink from 'components/AboutLink'
import UpcomingMatch from './upcoming-match'

export default function NextMatch() {
  const router = useRouter()
  const { id, slug } = router.query
  const { data: schedule, error } = useTheDozenSchedule()

  if (error) return null

  const nextMatch = schedule?.filter((item) => item.upcoming_game)?.[0]
  return (
    <>
      <SidebarWidget>
        <div>
          <h3>Next Match</h3>
          {nextMatch && (
            <div className='next-match'>
              <UpcomingMatch {...nextMatch} matches={[nextMatch]} />
            </div>
          )}
        </div>
        <AboutLink href='/shows/[id]/[slug]/schedule' as={`/shows/${id}/${slug}/schedule`}>
          See Full Schedule
        </AboutLink>
      </SidebarWidget>
      <style jsx>{`
        .next-match {
          margin-top: -15px;
        }
        h3 {
          padding-top: 0;
          margin-top: 0;
          margin-bottom: 18px;
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 29px;
          text-transform: uppercase;
        }
        .table-wrapper {
          padding: 10px 0 0 0;
        }
      `}</style>
    </>
  )
}
