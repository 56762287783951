import { THEME } from 'utils'

export default function TeamColumn({ team_name, team_logo = '/images/barstool.svg', table = false }) {
  return (
    <>
      <div className='team-col-wrapper'>
        <img src={team_logo} className='team-logo' />
        <span>{team_name}</span>
      </div>
      <style jsx>{`
        .team-col-wrapper {
          display: flex;
          align-items: center;
          gap: 10px;
          min-width: 232px;
          width: 100%;
        }
        .team-logo {
          max-width: 48px;
        }
        span {
          letter-spacing: 0;
        }
        @media (max-width: ${THEME.BREAKPOINTS.MD - 1}px) {
          .team-col-wrapper {
            gap: 6px;
            min-width: 150px;
          }
          .team-logo {
            max-width: ${table ? '32px' : '48px'};
          }
        }
      `}</style>
    </>
  )
}
